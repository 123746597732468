import React from "react"

const FeaturedServices = ({ featuredServices }) => {
  const title = featuredServices?.title
  const shortDesc = featuredServices?.shortDesc
  const services = featuredServices?.services

  return (
    <section className="featured-services-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{title}</h2>
          <h3 style={{ marginTop: "10px" }}>
            Creating Foundations That Guarantee Success{" "}
          </h3>
          <p>{shortDesc}</p>
        </div>

        <div className="row">
          {services.map((service, index) => {
            const { title, longDesc, icon } = service
            return (
              <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                <div className="single-featured-services-box">
                  <div className="icon">
                    <i className={icon}></i>
                  </div>
                  <h3>{title}</h3>
                  <p>{longDesc}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default FeaturedServices
