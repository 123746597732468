import Loadable from "@loadable/component"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import shape10 from "../../assets/images/shape/vector-shape10.png"
import shape9 from "../../assets/images/shape/vector-shape9.png"

const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const query = graphql`
  {
    strapiTestimonials {
      title
      longDesc
      item {
        designation
        feedbackText
        name
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`
const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  margin: 30,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
  },
}

const Testimonials = () => {
  const {
    strapiTestimonials: { title, longDesc, item: testimonials },
  } = useStaticQuery(query)

  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <section className="testimonials-area bg-23173a pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{title}</h2>
          <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
        </div>

        {display ? (
          <OwlCarousel
            className="testimonials-slides owl-carousel owl-theme"
            {...options}
          >
            {testimonials.map((testimonial, index) => {
              const {
                name,
                designation,
                feedbackText,
                image: {
                  localFile: {
                    childImageSharp: {
                      original: { src: imageSrc },
                    },
                  },
                },
              } = testimonial
              return (
                <div className="single-testimonials-box" key={index}>
                  <img
                    src={imageSrc}
                    className="shadow-sm"
                    alt="testimonials"
                  />
                  <p>{feedbackText}</p>

                  <div className="client-info">
                    <div className="title">
                      <h3>{name}</h3>
                      <span>{designation}</span>
                    </div>
                  </div>
                </div>
              )
            })}
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>

      <div className="vector-shape9">
        <img src={shape9} alt="testimonials" />
      </div>
      <div className="vector-shape10">
        <img src={shape10} alt="testimonials" />
      </div>
    </section>
  )
}

export default Testimonials
