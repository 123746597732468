import React from "react"
import { Link } from "gatsby"
import MapShape1 from "../../assets/images/shape/map-shape1.png"
import VectorShape5 from "../../assets/images/shape/vector-shape5.png"
import StarIcon from "../../assets/images/star-icon.png"
import VectorShape4 from "../../assets/images/shape/vector-shape4.png"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    strapiTeamStyle2 {
      title
      shortDesc
      longDesc
      teamCard {
        name
        image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`
const TeamMembers = () => {
  const {
    strapiTeamStyle2: { title, shortDesc, longDesc, teamCard },
  } = useStaticQuery(query)

  const {
    localFile: {
      childImageSharp: {
        original: { src: teamMemberImage1 },
      },
    },
  } = teamCard[0].image

  const {
    localFile: {
      childImageSharp: {
        original: { src: teamMemberImage2 },
      },
    },
  } = teamCard[1].image
  const {
    localFile: {
      childImageSharp: {
        original: { src: teamMemberImage3 },
      },
    },
  } = teamCard[2].image
  return (
    <div className="scientist-area pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12">
            <div className="scientist-box-list">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-md-6 offset-lg-1">
                  <div className="single-scientist-item">
                    <img src={teamMemberImage1} alt="Scientist" />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-md-6">
                  <div className="single-scientist-item">
                    <img src={teamMemberImage2} alt="Scientist" />
                  </div>
                </div>

                <div className="col-lg-6 col-sm-12 col-md-6 offset-lg-0 offset-md-3">
                  <div className="single-scientist-item">
                    <img src={teamMemberImage3} alt="Scientist" />
                  </div>
                </div>
              </div>

              <div className="map-shape1">
                <img src={MapShape1} alt="Map Shape" />
              </div>
              <div className="vector-shape5">
                <img src={VectorShape5} alt="Vector Shape" />
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-12">
            <div className="scientist-section-title">
              <span className="sub-title">
                <img src={StarIcon} alt="Star Icon" />
                {title}
              </span>
              <h2>
                <i>{shortDesc}</i>
              </h2>
              <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
              <Link to="/team" className="default-btn">
                <i className="flaticon-view"></i>
                View Our Team <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="vector-shape4">
        <img src={VectorShape4} alt="Vector Shape" />
      </div>
    </div>
  )
}

export default TeamMembers
