import React from "react"
import TransitionBoxes from "./TransitionBoxes"

const StartUp = ({ allHomeDetails }) => {
  const homeTabs = allHomeDetails?.nodes

  return <TransitionBoxes homeTabs={homeTabs}></TransitionBoxes>
}

export default StartUp
