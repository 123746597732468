import React from "react"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import VectorShape11 from "../../assets/images/shape/vector-shape11.png"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

const query = graphql`
  {
    strapiIndustriesWeServe {
      title
      longDesc
      industries {
        name
        icon
      }
    }
  }
`
const WeServe = () => {
  const {
    strapiIndustriesWeServe: { title, longDesc, industries },
  } = useStaticQuery(query)
  return (
    <section className="industries-serve-area bg-fafafb pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>{title}</h2>
          <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
          <Link to="/contact" className="default-btn">
            <i className="flaticon-right"></i>
            Let Us Know <span></span>
          </Link>
        </div>
        <div className="row">
          {industries?.map((industry, index) => {
            const { name, icon } = industry
            return (
              <div className="col-lg-3 col-sm-6 col-md-6" key={index}>
                <div className="single-industries-serve-box">
                  <div className="icon">
                    <i className={icon}></i>
                  </div>
                  {name}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className="vector-shape11">
        {/* FIXME: Background Side Image - Make it dynamic .i.e it should come from strapi */}
        <img src={VectorShape11} alt="Vector Shape" />
      </div>
    </section>
  )
}

export default WeServe
