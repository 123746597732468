import React from "react"

const FunFacts = ({ funFactsDetails }) => {
  const funFactsData = funFactsDetails

  return (
    <div className="funfacts-area bg-image pt-100 pb-70">
      <div className="container">
        <div className="row">
          {funFactsData?.funFacts?.map((funFact, index) => {
            const {
              header,
              shortDesc,
              icon: {
                localFile: {
                  childImageSharp: {
                    original: { src: imageSrc },
                  },
                },
              },
            } = funFact
            return (
              <div
                className="col-lg-3 col-sm-3 col-6 col-md-3"
                key={`${funFactsData?.id}-${index}`}
              >
                <div className="single-funfacts-item">
                  <div className="icon">
                    <img src={imageSrc} alt="FunIcon" />
                  </div>
                  <h3>{header}</h3>
                  <p>{shortDesc}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FunFacts
