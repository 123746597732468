import React from "react"
import aIcon from "../../assets/images/start-up/a-icon.svg"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
// This will be a sibling component
const TransitionBox = ({ homeTab }) => {
  const { id, title, slug } = homeTab
  return (
    <div
      className="col-lg-3 col-sm-6 col-6 border-end position-relative p-0 card-col"
      key={id}
    >
      <Link to={`/${slug}`}>
        <div className="before-hover h-100  ">
          <div className="block-out  m-top p-3">
            <h4 className="main-heading position-relative">
              <ReactMarkdown children={title} rehypePlugins={[rehypeRaw]} />
              <span className="yes-button">
                Click Here <i className="flaticon-right ms-2"></i>
              </span>
            </h4>
          </div>
          <div className="icon-arrow">
            <img src={aIcon} className="a-icon" alt="aIcon" />
          </div>
        </div>
      </Link>
    </div>
  )
}

const TransitionBoxes = ({ homeTabs }) => {
  return (
    <>
      <section className="trainsiton-boxes-section overflow-hidden">
        <div className="container-fluid h-100 p-0">
          <div className="row m-0 h-100">
            {homeTabs
              ?.filter(item => {
                return item.id !== "Home_5" //Filter the default banner
              })
              ?.map((homeTab, index) => (
                <TransitionBox homeTab={homeTab} key={index} />
              ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default TransitionBoxes
