import React from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

const WhatWeDo = ({ whatWeDoDetails, featuredServices }) => {
  const title = whatWeDoDetails?.title
  const longDesc = whatWeDoDetails?.longDesc
  const services = featuredServices?.services
  return (
    <>
      <section className="what-we-do-area bg-fafafb pt-100">
        <div className="container">
          <div className="section-title">
            <h2>{title}</h2>
            <br />
            <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
          </div>
        </div>
      </section>

      <section className="featured-services-area pb-70">
        <div className="container">
          <div className="row">
            {services.map((service, index) => {
              const { title, longDesc, icon } = service
              return (
                <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                  <div className="single-featured-services-box">
                    <div className="icon">
                      <i className={icon}></i>
                    </div>
                    <h3>{title}</h3>
                    <p>{longDesc}</p>
                  </div>
                </div>
              )
            })}
          </div>

          <div>
            <div
              style={{
                marginTop: "15px",
                textAlign: "center",
              }}
            >
              <h3>Turn Your Dream Project Into A Success!</h3>
              <Link to="/contact" className="default-btn">
                <i className="flaticon-right"></i>
                Let's Work <span></span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhatWeDo
