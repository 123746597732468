import { Link } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import MapShape2 from "../../assets/images/shape/map-shape2.png"

const WhyChooseUs = ({ whyChooseUs, partnerDetails }) => {
  const title = whyChooseUs?.title
  const longDesc = whyChooseUs?.longDesc
  const partner = partnerDetails?.partner

  return (
    <section className="why-choose-us-area bg-color pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="why-choose-us-content">
              <h3>{title}</h3>
              <ReactMarkdown children={longDesc} rehypePlugins={[rehypeRaw]} />
              <Link to="/about-us" className="default-btn">
                <i className="flaticon-right"></i>
                More About Us <span></span>
              </Link>

              <div className="map-shape2">
                <img src={MapShape2} alt="MapShape" />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="our-brand-partners-list">
              <h3>Our Benchmark Infrastructure Tools</h3>
              <div className="row align-items-center">
                {partner.map((partner, index) => {
                  const {
                    image: {
                      localFile: { url: imageSrc },
                    },
                  } = partner
                  return (
                    <div
                      key={index}
                      className="col-lg-4 col-sm-6 col-md-4 pe-5 pb-3"
                    >
                      <img src={imageSrc} alt="Partner" />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
