import { graphql } from "gatsby"
import React from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import StartProject from "../components/Common/StartProject"
import FeaturedServices from "../components/Index/FeaturedServices"
import FunFacts from "../components/Index/FunFacts"
import MainBanner from "../components/Index/MainBanner"
import Services from "../components/Index/Services"
import StartUp from "../components/Index/StartUp"
import TeamMembers from "../components/Index/TeamMembers"
import Testimonials from "../components/Index/Testimonials"
import WeServe from "../components/Index/WeServe"
import WhatWeDo from "../components/Index/WhatWeDo"
import WhyChooseUs from "../components/Index/WhyChooseUs"
import Seo from "../components/App/Seo"

const Home = ({ data }) => {
  const {
    metaTags,
    allHomeDetails,
    defaultBannerDetails,
    featuredServicesDetails,
    whyChooseUsDetails,
    partnerDetails,
    whatWeDoDetails,
    funFactsDetails,
    startYourProjectDetails,
  } = data
  const { metaImage } = metaTags

  const pageMeta = metaTags?.metas?.filter(item => {
    return item.page === "home"
  })[0]

  return (
    <Layout>
      <Seo
        title={pageMeta?.metaTitle}
        description={pageMeta?.metaDesc}
        keywords={pageMeta?.metaKeyword}
        metaImage={metaImage}
        url={""}
      />
      <Navbar />
      <StartUp allHomeDetails={allHomeDetails} />
      <MainBanner defaultBanner={defaultBannerDetails} />
      <FeaturedServices featuredServices={featuredServicesDetails} />
      <FunFacts funFactsDetails={funFactsDetails} />
      <WhyChooseUs
        whyChooseUs={whyChooseUsDetails}
        partnerDetails={partnerDetails}
      />
      <WhatWeDo
        whatWeDoDetails={whatWeDoDetails}
        featuredServices={featuredServicesDetails}
      />
      <Services />
      <Testimonials />
      <WeServe />
      <TeamMembers />
      <StartProject strapiStartYourProject={startYourProjectDetails} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleHomeDetails {
    metaTags: strapiMetaTags {
      metaImage {
        url
      }
      metas {
        id
        page
        metaTitle
        metaKeyword
        metaDesc
      }
    }
    allHomeDetails: allStrapiHome(sort: { fields: id, order: ASC }) {
      nodes {
        id
        title
        slug
      }
    }
    defaultBannerDetails: strapiDefaultBanner {
      header
      helpText
      btnText
      btnLink
    }
    featuredServicesDetails: strapiFeaturedServices {
      title
      shortDesc
      services {
        longDesc
        title
        icon
      }
    }
    whyChooseUsDetails: strapiWhyChooseUs {
      title
      longDesc
    }
    partnerDetails: strapiPartner {
      partner {
        image {
          localFile {
            url
          }
        }
      }
    }
    whatWeDoDetails: strapiWhatWeDo {
      title
      longDesc
    }
    funFactsDetails: strapiFunFacts {
      id
      funFacts {
        id
        shortDesc
        header
        icon {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
    startYourProjectDetails: strapiStartYourProject {
      id
      title
      shortText
      btnLink
      btnText
      images {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  }
`

export default Home
