import React from "react"
import { Link } from "gatsby"
import ServiceShape4 from "../../assets/images/services/service-shape4.png"

import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    allStrapiServiceSolution(sort: { fields: order, order: ASC }) {
      nodes {
        title
        slug
        homeDesc
        iconImage {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`

const Services = () => {
  const {
    allStrapiServiceSolution: { nodes: services },
  } = useStaticQuery(query)
  return (
    <section className="services-area ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>What We Offer?</h2>
          <h3>
            Explore Our Range of Enterprise Architecture Consulting Services
          </h3>
          {/* FIXME: Description is not present there in the doc provided. Remove after discussion */}
          {/* <p>
            Groundbreaking revelations during the planning process will allow
            your business to expand beyond limits.
          </p> */}
        </div>

        <div className="row">
          {services.map((service, index) => {
            const {
              title,
              slug,
              homeDesc,
              iconImage: {
                localFile: {
                  childImageSharp: {
                    original: { src: iconImageSrc },
                  },
                },
              },
            } = service
            return (
              <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="single-services-box-item ">
                  <div className="icon">
                    <img src={iconImageSrc} alt="Service Icon" />
                  </div>
                  <h3>
                    <Link to={`/services/${slug}`}>{title}</Link>
                  </h3>
                  <p>{homeDesc}</p>
                  <Link to={`/services/${slug}`} className="learn-more-btn">
                    <i className="flaticon-right"></i> Learn More
                  </Link>
                  <div className="shape">
                    {/* FIXME: Background Side Image - Make it dynamic .i.e it should come from strapi */}
                    <img src={ServiceShape4} alt="Service Shape" />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div>
          <div
            style={{
              marginTop: "15px",
              textAlign: "center",
            }}
          >
            <h3>Share your unique business perspective! </h3>
            <Link to="/contact" className="default-btn">
              <i className="flaticon-right"></i>
              Reach Our Team! <span></span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
